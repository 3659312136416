<template>
  <div
    class="join-support"
    id="support"
  >
    <div class="join-support-cont">
      <AboutTitle :data="{
      en: baseInfo.joinSupportEnglish,
      ch: baseInfo.joinSupportChinese,
    }" />
      <div v-loading="loading">
        <div
          class="join-support-res"
          v-if="listData.length"
        >
          <div class="join-support-tabs">
            <div
              class="item"
              v-for="(item, index) in listData"
              :key="item.id"
              @click="changeTab(index, item)"
              :class="{ active: index === currentTabIndex }"
            >{{ item.holdTitle }}</div>
          </div>
          <div
            class="join-support-tab-content"
            data-aos="fade-up"
          >
            <ScaleImage class="pic">
              <PlaceholderImage
                :src="currentTabContent.holdImage"
                :width="720"
                :height="400"
                :alt="currentTabContent.holdTitle"
              />
            </ScaleImage>
            <div class="txts">
              <div
                class="desc"
                v-html="currentTabContent.holdContent"
              ></div>
            </div>
          </div>
        </div>
        <el-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { AboutTitle } from '../../components/Title';
import { mapState } from 'vuex';
import { getHold } from '@/api/ShuJiuXiang/join';
import { Scale, Placeholder } from '@/components/Image';
export default {
  components: {
    AboutTitle,
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  data() {
    return {
      listData: [],
      currentTabIndex: 0,
      currentTabContent: {},
      loading: false,
    };
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.join.baseInfo,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const res = await getHold();
        this.loading = false;
        this.listData = res.map((d) => ({ ...d, id: d.holdId }));
        this.currentTabContent = this.listData[0] || {};
      } catch (err) {
        this.loading = false;
      }
    },
    changeTab(index, itemData) {
      this.currentTabIndex = index;
      this.currentTabContent = itemData;
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.join-support {
  padding: 0 math.percentage(math.div(190, 1920)) 85px 295px;
  &-cont {
    border-top: 1px solid $border-color-6;
    padding-top: 48px;
  }
  &-tab-content {
    display: flex;
    justify-content: space-between;
    .pic {
      width: math.percentage(math.div(720, 1434));
    }
    .txts {
      width: math.percentage(math.div(565, 1434));
      margin-right: math.percentage(math.div(60, 1434));
      padding-top: 200px;
    }
    .desc {
      border-top: 1px solid $border-color-6;
      font-size: 18px;
      line-height: 2;
      color: $text-color-3;
      padding-top: 35px;
    }
    @media screen and (max-width: $res-point-1) {
      .desc {
        font-size: 16px;
        padding-top: 35px * 0.8;
      }
      .txts {
        padding-top: 200px * 0.8;
        margin-right: 0;
      }
    }
    @media screen and (max-width: $res-point-2) {
      .txts {
        width: math.percentage(math.div(565 + 80, 1434));
      }
    }
    @media screen and (max-width: $res-point-3) {
      .desc {
        font-size: 14px;
        padding-top: 35px * 0.8 * 0.8;
      }
      .txts {
        padding-top: 200px * 0.8 * 0.8;
      }
    }
  }
  &-res {
    position: relative;
    margin-top: 60px;
  }
  &-tabs {
    position: absolute;
    width: math.percentage(math.div(565, 1434));
    z-index: 29;
    top: 30px;
    right: math.percentage(math.div(60, 1434));
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25%;
      position: relative;
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      @include text-nowrap;
      padding-left: 18px;
      cursor: pointer;
      transition: 300ms;
      margin-bottom: 35px;
      &::before {
        content: '';
        width: 2px;
        height: 15px;
        left: 0;
        position: absolute;
        top: 6px;
        background: $text-font-5;
        transition: 300ms;
      }
      &.active {
        color: $sub-color-2;
        &::before {
          background: $sub-color-2;
        }
      }
      @media screen and (min-width: $hover-point) {
        &:hover {
          color: $sub-color-2;
        }
      }
    }
    @media screen and (max-width: $res-point-1) {
      top: 30px * 0.8;
      right: 0;
      .item {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 35px * 0.8;
        &::before {
          height: 13px;
        }
      }
    }
    @media screen and (max-width: $res-point-2) {
      width: math.percentage(math.div(565 + 80, 1434));
    }
    @media screen and (max-width: $res-point-3) {
      top: 30px * 0.8 * 0.8;
      .item {
        font-size: 16px;
        margin-bottom: 35px * 0.8 * 0.8;
        padding-left: 18px * 0.8;
      }
    }
  }
  @media screen and (max-width: $res-point-3) {
    padding-left: 295px - 60px;
  }
  @media screen and (max-width: $res-point-5) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (max-width: $res-point-6) {
    &-tabs {
      position: static;
      width: 100%;
      margin-bottom: 30px;
      .item {
        width: 16.66%;
        margin-bottom: 10px;
      }
    }
    &-tab-content {
      .txts {
        padding-top: 0;
        flex: 1;
        width: auto;
      }
      .desc {
        border-top: none;
        padding-top: 0;
        line-height: 1.5;
      }
      .pic {
        margin-right: 5%;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    padding-bottom: 85px * 0.8 * 0.8;
    &-tabs {
      .item {
        font-size: 14px;
        padding-left: 10px;
        width: 20%;
        margin-bottom: 5px;
        &::before {
          width: 1px;
          height: 9px;
          top: 8px;
        }
      }
    }
    &-res {
      margin-top: 60px * 0.8 * 0.8;
    }
    &-cont {
      padding-top: 48px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-9) {
    padding-bottom: 30px;
    &-cont {
      padding-top: 48px * 0.8 * 0.8;
    }
    &-res {
      margin-top: 60px * 0.8 * 0.8 * 0.8;
    }
    &-tabs {
      margin-bottom: 30px * 0.8 * 0.8;
      .item {
        width: 25%;
      }
    }
    &-tab-content {
      flex-wrap: wrap;
      .pic {
        width: 100%;
        margin-right: 0;
      }
      .txts {
        padding-top: 10px;
      }
    }
  }
}
</style>