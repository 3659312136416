<template>
  <div class="page-join">
    <PageBannerBox>
      <FSImg :imgUrl="baseInfo.joinImage" />
      <BannerText
        :en="baseInfo.joinEnglish"
        :ch="baseInfo.joinChinese"
        :navList="navList"
        @changeActiveNav="changeActiveNav"
        :active="currentNav"
      />
      <SJXPoint v-if="!isMobile" />
    </PageBannerBox>
    <PageContent>
      <JoinAdvantage />
      <JoinSupport />
      <JoinContact />
      <div class="join-msg">
        <HomeJoin
          class="join-msg-cont"
          id="message"
          :isHideDetail="true"
        />
      </div>
    </PageContent>
    <SideNav
      :navList="navList"
      :active="currentNav"
      @changeActiveNav="changeActiveNav"
      v-if="!isNearMobile"
    />
  </div>
</template>

<script>
import { FSImg } from '@/components/Banner';
import BannerText from '../components/BannerText';
import PageBannerBox from '../components/Layout/PageBannerBox.vue';
import { SJXPoint } from '@/components/DownPoint';
import PageContent from '../components/Layout/PageContent.vue';
import SideNav from '../components/SideNav';
import { mapState } from 'vuex';
import { getBaseInfo } from '@/api/ShuJiuXiang/join';
import JoinAdvantage from './components/Advantage.vue';
import JoinSupport from './components/Support.vue';
import JoinContact from './components/Contact.vue';
import HomeJoin from '../Home/components/Join.vue';
import { getPointsPos, getScrollTop } from '@/utils/common';
import $ from 'jquery';
import _ from 'lodash';

const navList = [
  { text: '加盟优势', key: 'advantage' },
  { text: '加盟支持', key: 'support' },
  { text: '联系方式', key: 'contact' },
  { text: '留言表单', key: 'message' },
];

export default {
  components: {
    FSImg,
    BannerText,
    PageBannerBox,
    SJXPoint,
    PageContent,
    SideNav,
    JoinAdvantage,
    JoinSupport,
    JoinContact,
    HomeJoin,
  },
  data() {
    return {
      navList,
      currentNav: '',
      pointsPos: [],
    };
  },
  mounted() {
    this.getBaseInfo();
    this.init();
  },
  destroyed() {
    document.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    async init() {
      const res = await getPointsPos(this.navList);
      this.pointsPos = res;
      if (this.isNearMobile) {
        return;
      }
      this.onScroll();
      document.addEventListener('scroll', _.debounce(this.onScroll));
    },
    changeActiveNav(key) {
      const index = navList.findIndex((d) => d.key === key);
      $('html,body').stop().animate({ scrollTop: this.pointsPos[index] }, 800);
      this.currentNav = key;
    },
    // 获取基本数据
    async getBaseInfo() {
      const res = await getBaseInfo();
      this.$store.commit('sjx/join/BASE_INFO', res);
    },
    onScroll() {
      const st = getScrollTop() + this.visibleWH.height * 0.5;
      let len = this.pointsPos.length - 1;
      let index = 0;
      while (index <= len) {
        const next = this.pointsPos[index + 1] || Infinity;
        if (st >= this.pointsPos[index] && st < next) {
          this.currentNav = this.navList[index].key;
          break;
        }
        index++;
      }
    },
  },
  computed: {
    ...mapState({
      isMobile: 'isMobile',
      isNearMobile: 'isNearMobile',
      visibleWH: 'visibleWH',
      baseInfo: (state) => state.sjx.join.baseInfo,
    }),
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.page-join {
  ::v-deep .pageContent {
    padding: 0;
  }
  @media screen and (max-width: $res-point-6) {
    padding-top: 60px;
  }
}
.join-msg {
  padding: math.percentage(math.div(155, 1920)) math.percentage(math.div(190, 1920))
    math.percentage(math.div(187, 1920)) 295px;
  &-cont {
    padding-bottom: 0;
  }
  @media screen and (max-width: $res-point-3) {
    padding-left: 295px - 60px;
  }
  @media screen and (max-width: $res-point-5) {
    padding-left: 5%;
    padding-right: 5%;
  }
}
</style>
