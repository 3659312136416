<template>
  <div class="join-advantage-card">
    <div class="titleBox">
      <div class="title">
        <p class="ch">{{ data.meritTitle }}</p>
        <p class="en">Product <br /> Advantages</p>
      </div>
      <img
        :src="data.meritImage"
        :alt="data.meritTitle"
        class="icon1"
      />
    </div>
    <p
      v-html="data.meritContent"
      class="desc"
    ></p>
    <img
      :src="data.meritImage1"
      :alt="data.meritTitle"
      class="icon2"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/mixin.scss';
@import '@/styles/ShuJiuXiang/theme.scss';
.join-advantage-card {
  flex: auto;
  background: $sub-color-2;
  color: #fff;
  padding: 40px 30px 0;
  height: 250px;
  position: relative;
  transition: 300ms;
  width: 16.66%;
  &:nth-child(2n) {
    background: #2e4e86;
  }
  &:nth-child(3n) {
    background: #3a6298;
  }
  &:nth-child(4n) {
    background: #4d73a5;
  }
  &:nth-child(5n) {
    background: #587cad;
  }
  &:nth-child(6n) {
    background: #90a9cb;
  }
  .titleBox {
    display: flex;
    justify-content: space-between;
    .ch {
      font-size: 18px;
      font-weight: bold;
      line-height: math.div(24, 18);
      transition: 300ms;
    }
    .en {
      font-size: 12px;
      line-height: math.div(20, 12);
      margin-top: 15px;
      transition: 300ms;
    }
  }
  .icon2,
  .icon1 {
    width: 38px;
    height: 38px;
    position: absolute;
  }
  .desc,
  .icon1 {
    @include hide;
    transition: 300ms;
  }
  .desc {
    margin-top: 35px;
    line-height: math.div(19, 14);
  }
  .icon2 {
    right: 35px;
    bottom: 35px;
    transition: 300ms;
  }
  .icon1 {
    right: 35px;
    top: 37px;
  }
  @media screen and (min-width: $res-point-11) {
    &:hover {
      .titleBox .ch {
        font-size: 24px;
      }
    }
  }
  @media screen and (min-width: $hover-point) {
    &:hover {
      width: 30%;
      background: #fff;
      color: $sub-color-2;
      .titleBox .ch {
        font-size: 24px * 0.8;
      }
      .titleBox .en,
      .icon2 {
        @include hide;
      }
      .desc,
      .icon1 {
        @include show;
      }
      .desc {
        color: $text-color-3;
      }
    }
  }
  @media screen and (max-width: $res-point-1) {
    padding-left: 40px * 0.8 * 0.8;
    padding-right: 40px * 0.8 * 0.8;
    .icon2 {
      right: 35px * 0.8 * 0.8;
    }
    .icon1 {
      right: 35px * 0.8 * 0.8;
    }
  }
  @media screen and (max-width: $res-point-2) {
    padding: 30px 20px 0;
    .icon2,
    .icon1 {
      transform: scale(0.8);
      transform-origin: right center;
    }
    .icon2 {
      right: 20px;
      bottom: 25px;
    }
    .icon1 {
      right: 20px;
      top: 25px;
    }
  }
  @media screen and (max-width: $res-point-6) {
    width: 33.33%;
    .desc {
      @include show;
    }
    .icon2 {
      bottom: auto;
      top: 35px;
    }
    .icon1 {
      display: none;
    }
    .titleBox {
      .en {
        line-height: 1.2;
        margin-top: 10px;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    width: 50%;
  }
  @media screen and (max-width: $res-point-9) {
    width: 100%;
    border-radius: 6px;
    box-shadow: $card-shadow-1;
    height: auto;
    padding: 30px 5%;
    + .join-advantage-card {
      margin-top: 15px;
    }
  }
}
</style>