<template>
  <div
    id="advantage"
    class="join-advantage"
  >
    <StoryTitle>{{ baseInfo.joinTitleEnglish }}</StoryTitle>
    <div class="join-advantage-cont">
      <AboutTitle :data="{
      en: baseInfo.joinAdvantageEnglish,
      ch: baseInfo.joinAdvantageChinese,
    }" />
      <div v-loading="loading">
        <div
          class="join-advantage-list"
          v-if="listData.length"
          data-aos="fade-up"
        >
          <JoinAdvantage
            v-for="(item) in listData"
            :key="item.id"
            :data="item"
          />
        </div>
        <el-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { StoryTitle, AboutTitle } from '../../components/Title';
import { mapState } from 'vuex';
import JoinAdvantage from '../../components/Card/JoinAdvantage.vue';
import { getMerit } from '@/api/ShuJiuXiang/join';
export default {
  components: {
    StoryTitle,
    AboutTitle,
    JoinAdvantage,
  },
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.join.baseInfo,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const res = await getMerit();
        this.loading = false;
        this.listData = res.map((d) => ({ ...d, id: d.meritId }));
      } catch (err) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.join-advantage {
  padding: math.percentage(math.div(160, 1920)) math.percentage(math.div(190, 1920))
    math.percentage(math.div(120, 1920)) 235px;
  &-cont {
    margin-left: 60px;
    border-top: 1px solid $border-color-6;
    padding-top: 28px;
    margin-top: 28px;
  }
  &-list {
    display: flex;
    margin-top: 60px;
  }
  @media screen and (max-width: $res-point-3) {
    padding-left: 235px - 60px;
  }
  @media screen and (max-width: $res-point-5) {
    padding-left: 5%;
    padding-right: 5%;
    &-cont {
      margin-left: 0;
    }
  }
  @media screen and (max-width: $res-point-6) {
    &-list {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: $res-point-8) {
    &-list {
      margin-top: 60px * 0.8 * 0.8;
    }
    &-cont {
      padding-top: 28px * 0.8;
      margin-top: 28px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-9) {
    &-list {
      margin-top: 60px * 0.8 * 0.8 * 0.8;
    }
    &-cont {
      padding-top: 28px * 0.8 * 0.8;
      margin-top: 28px * 0.8 * 0.8;
    }
  }
}
</style>