<template>
  <div
    class="join-contact"
    id="contact"
  >
    <div class="join-contact-cont">
      <AboutTitle :data="{
      en: baseInfo.joinContactEnglish,
      ch: baseInfo.joinContactChinese,
    }" />
      <div
        class="join-contact-list"
        v-loading="loading"
      >
        <div
          class="item tel"
          data-aos="fade-left"
        >
          <span class="base-iconfont base-icon-dianhua"></span>
          <div class="box">
            <a
              class="no-gesture"
              v-for="item in telData"
              :key="item.telId"
              :href="`tel:${item.telPhone}`"
            >{{ item.telName }}：{{ item.telPhone }}</a>
          </div>
        </div>
        <div
          class="item addr"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          <span class="base-iconfont base-icon-dizhi"></span>
          <div class="box">
            <a
              target="_blank"
              :href="baseInfo.joinCantactAddressUrl"
              v-for="item in addrData"
              :key="item.telId"
            >{{ item.telName }}：{{ item.telPhone }}</a>
          </div>
        </div>
        <div
          class="item other"
          data-aos="fade-left"
          data-aos-delay="400"
        >
          <span class="base-iconfont base-icon-gengduo"></span>
          <div class="box">
            <span
              v-for="item in otherData"
              :key="item.telId"
            >{{ item.telName }}：{{ item.telPhone }}</span>
          </div>
        </div>
      </div>
      <div
        id="map"
        data-aos="fade-up"
        data-aos-delay="600"
      ></div>
    </div>
  </div>
</template>

<script>
import { AboutTitle } from '../../components/Title';
import { mapState } from 'vuex';
import { ShowMap } from '@/utils/common';
import { getTel } from '@/api/ShuJiuXiang/join';
export default {
  components: {
    AboutTitle,
  },
  data() {
    return {
      listData: [],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      baseInfo: (state) => state.sjx.join.baseInfo,
    }),
    telData() {
      return this.listData.filter((d) => d.telType === 'phone');
    },
    addrData() {
      return this.listData.filter((d) => d.telType === 'address');
    },
    otherData() {
      return this.listData.filter((d) => d.telType === 'other');
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      try {
        const res = await getTel();
        this.listData = res;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    baseInfo() {
      const mapInfo = `
        <div style="font-size: 18px; color: #666;">
          <div>地址：${this.baseInfo.joinCantactAddress}</div>
          <div>电话：400-833-5330</div>
        </div>
      `;
      ShowMap(
        `${this.baseInfo.joinCantactAddressLongitude},${this.baseInfo.joinCantactAddressLatitude}`,
        mapInfo,
        '17'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.join-contact {
  padding: 0 math.percentage(math.div(190, 1920)) 0 295px;
  &-cont {
    border-top: 1px solid $border-color-6;
    padding-top: 48px;
  }
  &-list {
    display: flex;
    border-top: 4px solid $sub-color-2;
    margin-top: math.percentage(math.div(120, 1434));
    padding-top: math.percentage(math.div(50, 1434));
    .item {
      width: 33.33%;
      display: flex;
      + .item {
        margin-left: 23px;
      }
    }
    .addr {
      .box {
        > span,
        > a {
          width: 300px;
          max-width: 100%;
        }
      }
    }
    .base-iconfont {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $sub-color-2;
      color: $sub-color-2;
      font-size: 22px;
      margin-right: 47px;
    }
    .box {
      flex: 1;
      font-size: 20px;
      line-height: math.div(48, 20);
      overflow: hidden;
      font-weight: bold;
      > span,
      > a {
        display: block;
        color: $sub-color-2;
      }
      > a {
        @media screen and (min-width: $hover-point) {
          &:not(.no-gesture):hover {
            text-decoration: underline;
          }
        }
      }
    }
    @media screen and (max-width: $res-point-12) {
      .base-iconfont {
        margin-right: 47px * 0.8;
      }
      .box {
        font-size: 18px;
      }
    }
    @media screen and (max-width: $res-point-1) {
      .base-iconfont {
        margin-right: 20px;
        width: 56px * 0.8;
        height: 56px * 0.8;
        font-size: 22px * 0.8;
      }
      .box {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: $res-point-3) {
    padding-left: 295px - 60px;
  }
  @media screen and (max-width: $res-point-5) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (max-width: $res-point-6) {
    &-list {
      .base-iconfont {
        margin-right: 15px;
        width: 32px;
        height: 32px;
        font-size: 14px;
      }
      .box {
        font-size: 14px;
        line-height: 32px;
      }
    }
  }
  @media screen and (max-width: $res-point-7) {
    &-list {
      flex-wrap: wrap;
      border-width: 2px;
      .item {
        width: 50%;
        margin-bottom: 30px;
        + .item {
          margin-left: 0;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    &-cont {
      padding-top: 48px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-10) {
    &-list {
      border-width: 1px;
      .base-iconfont {
        margin-right: 10px;
      }
      .item {
        width: 100%;
        margin-bottom: 0;
        + .item {
          margin-top: 20px;
        }
      }
    }
    &-cont {
      padding-top: 48px * 0.8 * 0.8;
    }
  }
}
#map {
  height: 430px;
  margin-top: math.percentage(math.div(110, 1434));
  @media screen and (max-width: $res-point-7) {
    margin-top: math.percentage(math.div(110 - 30, 1434));
  }
  @media screen and (max-width: $res-point-10) {
    height: 330px;
  }
  @media screen and (max-width: $res-point-9) {
    height: 330px * 0.8;
  }
}
</style>